<template>
  <CCard border-color="primary">
    <CCardHeader @click="collapsed = !collapsed">
      <CIcon :name="collapsableIcon" v-if="collapsable" />
      {{ config.title }}
      <div v-if="!collapsed" class="card-header-actions">
        <CButton
          variant="outline"
          color="primary"
          size="sm"
          v-on:click.stop="imgForm.active = !imgForm.active"
          ><CIcon name="cil-pencil" />&nbsp;Modifica</CButton
        >
      </div>
    </CCardHeader>
    <CCardBody v-if="!collapsed">
      <img
        v-if="!imgForm.active && config.image_cropped"
        v-bind:src="config.image_cropped"
        :class="{ 'c-avatar-img': config.circle, 'img-fluid': !config.circle }"
      />
      <p v-if="!imgForm.active && !config.image_cropped">
        Nessuna immagine caricata
      </p>
      <cropper
        ref="cropper"
        v-if="imgForm.active && imgForm.body.image"
        :stencil-component="
          config.circle
            ? $options.components.CircleStencil
            : $options.components.RectangleStencil
        "
        class="cropper"
        :src="imgForm.body.image"
        :stencil-props="{
          aspectRatio: config.ratio,
        }"
        @change="change"
      ></cropper>
    </CCardBody>
    <CCardFooter v-if="imgForm.active && !collapsed">
      <input
        type="file"
        ref="file"
        @change="uploadImage($event)"
        accept="image/*"
        class="d-none"
      />
      <vue-ladda
        :loading="imgForm.loading"
        data-style="zoom-in"
        button-class="btn btn-primary btn-sm"
        @click.prevent="SaveImg"
        ><CIcon name="cil-save" />&nbsp;{{ $t("common.save_label") }}</vue-ladda
      >
      <vue-ladda
        :loading="imgForm.loading"
        data-style="zoom-in"
        button-class="btn btn-primary btn-sm"
        @click.prevent="$refs.file.click()"
        ><CIcon name="cil-image-plus"
      /></vue-ladda>
      <vue-ladda
        :loading="imgForm.loading"
        data-style="zoom-in"
        button-class="btn btn-primary btn-sm"
        @click.prevent="RemoveImg"
        ><CIcon name="cil-trash"
      /></vue-ladda>
    </CCardFooter>
  </CCard>
</template>

<script>
// import { CircleStencil, Cropper } from "vue-advanced-cropper";
import { Cropper } from "vue-advanced-cropper";

export default {
  name: "ImageCropper",
  props: ["config"],
  data() {
    return {
      collapsed: this.config.collapsed || false,
      imgForm: {
        loading: false,
        active: false,
        body: {
          id: this.config.obj_id,
          image_cropping: this.config.image_cropping,
          image: this.config.image,
        },
        errors: {},
      },
    };
  },
  computed: {
    collapsable() {
      return typeof this.config.collapsed !== "undefined";
    },
    collapsableIcon() {
      console.log("collapsed", this.collapsed);
      if (this.collapsed) {
        return "cil-chevron-right";
      } else {
        return "cil-chevron-bottom";
      }
    },
  },
  methods: {
    uploadImage(event) {
      // console.log("load image");
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imgForm.body.image = e.target.result;
          this.imgForm.body.image_cropping = "";
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },
    change({ coordinates }) {
      // console.log(coordinates, canvas);
      this.imgForm.body.image_cropping = [
        coordinates.left,
        coordinates.top,
        coordinates.left + coordinates.width,
        coordinates.top + coordinates.height,
      ].join(",");
    },
    SaveImg() {
      // console.log("save_data")
      // console.log(this.dataForm.body)
      this.imgForm.loading = true;
      this.imgForm.errors = {};
      // this.imgForm.body.image_cropping=""
      let formData = { id: this.imgForm.body.id };
      if (
        (!this.config.image && this.imgForm.body.image) ||
        this.imgForm.body.image.split("?")[0] != this.config.image.split("?")[0]
      ) {
        formData[this.config.image_name || "image"] = this.imgForm.body.image;
      }
      if (this.imgForm.body.image_cropping != this.config.image_cropping) {
        formData[this.config.cropping_name || "image_cropping"] =
          this.imgForm.body.image_cropping;
      }
      this.config.dm.update("", formData).then(
        (e) => {
          // console.log("after update");
          // console.log(e);
          this.$emit("img_updated", e);
          this.imgForm.loading = false;
          this.imgForm.active = false;
          this.$store.dispatch("toaster/add", {
            title: this.$t("user.profile.image.toast.ok.title"),
            text: this.$t("user.profile.image.toast.ok.message"),
            autohide: true,
          });
        },
        (reason) => {
          let errors = JSON.parse(reason[0].error.response);
          this.imgForm.errors = errors;
          this.imgForm.loading = false;
        }
      );
    },
    RemoveImg() {
      // console.log("save_data")
      // console.log(this.dataForm.body)
      this.imgForm.loading = true;
      this.imgForm.errors = {};
      // this.imgForm.body.image_cropping=""
      this.imgForm.body.image = "";
      this.imgForm.body.image_cropping = "";
      let formData = { id: this.imgForm.body.id };
      formData[this.config.image_name || "image"] = "";
      formData[this.config.image_cropping_name || "image_cropping"] = "";
      // console.log(this.imgForm.body);
      this.config.dm.update("", formData).then(
        (e) => {
          // console.log("after update");
          // console.log(e);
          this.$emit("img_updated", e);
          this.imgForm.loading = false;
          this.imgForm.active = false;
          this.$store.dispatch("toaster/add", {
            title: this.$t("user.profile.image.toast.remove.title"),
            text: this.$t("user.profile.image.toast.remove.message"),
            autohide: true,
          });
        },
        (reason) => {
          let errors = JSON.parse(reason[0].error.response);
          this.imgForm.errors = errors;
          this.imgForm.loading = false;
        }
      );
    },
  },
  components: {
    Cropper,
  },
};
</script>

<style scoped>
.cropper {
  /* height: 600px; */
  background: #ddd;
}
</style>
